<template>
  <v-col cols="10" sm="6" class="pa-0 mt-10 py-10 text-left mx-auto">
    <div class="font-bold text-2xl sm:text-4xl mt-10 pt-10 text-capitalize">
      {{ pageName }}
    </div>

    <v-skeleton-loader
      v-if="isLoad"
      type="paragraph"
      class="mt-3"></v-skeleton-loader>
    <div v-else-if="dataItems">
      <div class="mt-5 mb-10">
        <div
          class="font-bold text-base sm:text-xl my-5 text-left"
          v-if="bahasa">
          Lokasi
        </div>
        <div class="font-bold text-base sm:text-xl my-5 text-left" v-else>
          Location
        </div>
        <div class="font-lora font-bold text-base">
          {{ dataItems.name || "-" }}
        </div>
        <div class="font-lora text-base mb-10">
          {{ dataItems.address || "-" }}
        </div>
        <div class="font-bold text-base sm:text-xl my-5 text-left">
          {{ bahasa ? "Kontak" : "Contact" }}
        </div>
        <v-row class="font-lora text-base mb-10">
          <v-col cols="12">
            <v-icon color="grey darken-4">email</v-icon>
            {{ dataItems.email || "-" }}
          </v-col>
          <v-col cols="12">
            <v-icon color="grey darken-4">phone</v-icon>
            {{ dataItems.phone || "-" }}
          </v-col>
          <v-col cols="12">
            <v-icon color="grey darken-4">facebook</v-icon>
            <span
              style="cursor: pointer; text-decoration: underline"
              @click="goLink('https://facebook.com/' + dataItems.link_fb)">
              {{ dataItems.link_fb || "-" }}
            </span>
          </v-col>
          <v-col cols="12">
            <div class="flex items-center align-center gap-1">
              <img src="@/assets/images/Instagram.png" width="25" />
              <span
                style="cursor: pointer; text-decoration: underline"
                @click="goLink('https://instagram.com/' + dataItems.link_ig)">
                {{ dataItems.link_ig || "-" }}
              </span>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="flex items-start gap-1">
              <img src="@/assets/images/LI-In-Bug.png" width="25" />
              <span
                style="cursor: pointer; text-decoration: underline"
                class=""
                @click="
                  goLink(
                    'https://linkedin.com/company/' + dataItems.link_linkedin
                  )
                ">
                {{ dataItems.link_linkedin || "-" }}
              </span>
            </div>
          </v-col>
        </v-row>
        <div
          v-if="dataItems.desc"
          class="font-bold text-base sm:text-xl my-5 text-left">
          {{ bahasa ? "Informasi Lainnya" : "Other Information" }}
        </div>
        <div v-if="dataItems.desc" v-html="dataItems.desc"></div>
      </div>
    </div>
    <div v-else>
      {{
        bahasa
          ? "Mohon maaf untuk saat ini belum ada posisi yang tersedia."
          : `Sorry we're not hiring for this time.`
      }}
    </div>
  </v-col>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      require: false,
    },
  },
  data() {
    return {
      isLoad: false,
      dataItems: null,
      baseUrl: process.env.VUE_APP_BASE_URL,
      lastUpdated: "",
    };
  },
  computed: {
    pageName() {
      return this.language === "ID" ? "Hubungi Kami" : "Contact Us";
    },
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  watch: {
    language() {
      this.getData();
    },
    page() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    goLink(url) {
      window.open(url);
    },
    async getData() {
      this.isLoad = true;
      let param = {
        lang: this.language,
      };
      await axios
        .get(this.baseUrl + "/contact_us", { params: param })
        .then((response) => {
          if (response.data.code === 200) {
            this.dataItems = response.data.data;
            this.lastUpdated = response.data.data.updated_at
              ? moment(response.data.data.updated_at).format("DD MMMM YYYY")
              : "-";
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoad = false));
    },
  },
};
</script>
